// ** Initial State
const initialState = {
  allData: [],
  dataReceive: [],
  dataOnProgress: [],
  dataFinish: [],
  dataSummary: {},
  total: 1,
  params: null,
  selected: null,
  loading: false,
  error: null,
  success: false,
  dataDetail: [],
  totalDetail: 1,
  paramsDetail: null,
  scan_data: null
}

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA_DASHBOARD_PRODUKSI':
      return { ...state, allData: action.data, params: action.params }
    case 'GET_DATA_DASHBOARD_PRODUKSI_SUMMARY':
      return {
        ...state,
        dataSummary: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DATA_DASHBOARD_PRODUKSI_RECEIVE':
      return {
        ...state,
        dataReceive: action.data,
        total: action.totalPages,
        params: action.params
      }
      case 'GET_DATA_DASHBOARD_PRODUKSI_ON_PROGRESS':
    return {
      ...state,
      dataOnProgress: action.data,
      total: action.totalPages,
      params: action.params
    }
    case 'GET_DATA_DASHBOARD_PRODUKSI_FINISH':
    return {
      ...state,
      dataFinish: action.data,
      total: action.totalPages,
      params: action.params
    }
    case 'GET_DATA_DASHBOARD_PRODUKSI_DETAIL':
      return {
        ...state,
        dataDetail: action.data,
        totalDetail: action.totalPages,
        paramsDetail: action.params
      }
    case 'GET_DASHBOARD_PRODUKSI':
      return { ...state, selected: action.selected }
    case 'GET_DASHBOARD_PRODUKSI_BY_BARCODE':
      return { ...state, scan_data: action.scan_data }
    case 'ADD_DASHBOARD_PRODUKSI':
      return { ...state }
    case 'DELETE_DASHBOARD_PRODUKSI':
      return { ...state }
    case 'RESET_DASHBOARD_PRODUKSI':
      return {
        ...state,
        loading: false,
        error: null,
        success: false
      }
    case 'REQUEST_DASHBOARD_PRODUKSI':
      return {
        ...state,
        loading: true
      }
    case 'SUCCESS_DASHBOARD_PRODUKSI':
      return {
        ...state,
        loading: false,
        success: true
      }
    case 'ERROR_DASHBOARD_PRODUKSI':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return { ...state }
  }
}
export default reducers
